.page4-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 200px;
  font-family: Arial, sans-serif;
}

.text-entry {
  width: 100%;
  max-width: 600px;
  margin-bottom: 20px;
}

.text-entry label {
  display: block;
  font-size: 16px;
  margin-bottom: 5px;
}

.text-entry textarea {
  width: 100%;
  padding: 10px;
  font-size: 16px;
  border: 1px solid #ccc;
  border-radius: 5px;
  resize: none;
}

.device-selector {
  margin-bottom: 20px;
}

.device-selector label {
  font-size: 16px;
  margin-right: 10px;
}

.device-selector select {
  padding: 5px;
  font-size: 16px;
}

button {
  background-color: #007bff;
  color: white;
  border: none;
  padding: 10px 20px;
  font-size: 16px;
  border-radius: 5px;
  cursor: pointer;
}

button:disabled {
  background-color: #cccccc;
  cursor: not-allowed;
}

button.blinking {
  animation: blinking 1s infinite;
}

@keyframes blinking {
  0%, 100% { background-color: #007bff; }
  50% { background-color: #0056b3; }
}

.response-message {
  margin-top: 20px;
  font-size: 16px;
  color: green;
}

