.graph-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 90%;
  height: auto;
  margin: 0 auto;
  margin-top: 150px;
  background-color: #f8f8f8;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.chart-title {
  font-size: 18px;
  font-weight: bold;
  margin-bottom: 10px;
}

.floating-div {
  position: fixed;
  bottom: 20px;
  left: 20px;
  background-color: lightgrey;
  padding: 10px;
  border-radius: 5px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.select_bar {
  position: fixed;
  bottom: 20px;
  right: 20px;
  background-color: lightgrey;
  padding: 10px;
  border-radius: 5px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

/* Optional: Add styles for input and label */
.select_bar label {
  margin-right: 10px;
}

.select_bar input[type="range"] {
  width: 150px;
  margin-right: 10px;
  background-color: lightblue !important;
}

.select_bar span {
  font-weight: bold;

}

@media (max-width: 832px) and (min-height: 2268px) {
  .graph-container {
    width: 1000px;  /* Full width on narrow screens */
    height: 60vh; /* Adjust height based on the narrow screen */
  }
}

@media (min-width: 833px) {
  .graph-container {
    width: 90%;  /* Standard size on wider screens */
    height: auto;
  }
}

.device_selector {
          position: fixed;
          top: 100px;
          right: 20px;
          background-color: white;
          padding: 10px;
          border: 1px solid #ccc;
          border-radius: 8px;
          box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
          z-index: 1000;
}

.device_selector label {
  display: block;
  margin-bottom: 5px;
}

.device_selector select {
  margin-bottom: 10px;
  padding: 5px;
}

.device_selector button {
  display: block;
  width: 100%;
  padding: 8px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.device_selector button:hover {
  background-color: #0056b3;
}

 button {
          padding: 8px 16px;
          border: none;
          border-radius: 4px;
          background-color: #007bff;
          color: white;
          cursor: pointer;
          transition: background-color 0.3s;
        }

        button:disabled {
          background-color: #ccc;
          cursor: not-allowed;
        }

        button.blinking {
          animation: blink 1s infinite;
        }

        @keyframes blink {
          0%, 100% {
            background-color: #007bff;
          }
          50% {
            background-color: #0056b3;
          }
        }
