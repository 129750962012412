/* src/components/CV.module.css */

.cv-container {
  max-width: 800px;
  margin: 0 auto;
  padding-top: 100px;
  position: relative;
  transition: padding-top 0.3s ease-in-out;
}

.cv-container.scrolled {
  padding-top: 60px; /* Adjust the padding to accommodate the fixed image */
}

header {
  text-align: center;
}

h1 {
  margin: 0;
}

p {
  margin: 5px 0;
}

.contact,
.education,
.experience,
.skills {
  margin-top: 20px;
}

.skills ul {
  list-style: none;
  padding: 0;
}

.skills li {
  margin-bottom: 5px;
}

.clickable-heading {
  cursor: pointer;
  color: #000; /* or any other color you prefer */
  transition: color 0.3s ease-in-out;
}

.clickable-heading:hover {
  color: #004080;
  margin-left: 5px; /* Shift it a bit to the right */
}

.active {
  /* Style for the active/clicked state if needed */
  font-weight: bold;
  color: #ffc107; 
  /*color: #004080;  or any other color */
  font-size: 1.5em; /* Increase the font size as needed */
  margin-left: 7px; /* Shift it a bit to the right */

}

.note-content {
  background-color: #f9f9f9; /* Background color for the note */
  padding: 10px; /* Padding around the content */
  border: 1px solid #ddd; /* Border for the note */
  border-radius: 5px; /* Rounded corners for the note */
  margin-top: 10px; /* Margin at the top of the note */
}

/*.global-job-photo {
  width: 150px; 
  height: 150px; 
  object-fit: cover; 
  position: relative;
  top: 5px; 
  right: 10px; 
  border-radius: 10px; 
  z-index: 2; 
}
*/

.global-job-photo {
  width: 150px;
  height: 150px;
  object-fit: cover;
  position: relative;
  top: 10px;
  right: 10px;
  border-radius: 15px;
  transition: top 0.5s ease-in-out;
}

.cv-container.scrolled .global-job-photo {
  top: 100px; /* Adjust the top position when scrolled */
  width: 80px;
  height: 80px;
  object-fit: cover;
  position: fixed;
  border-radius: 25px;
  opacity: 0.7;
}
