/* Navbar.css */

.navbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #333;
  padding: 10px;
  transition: background-color 0.3s ease;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 1000;
}

.nav-list {
  list-style: none;
  padding: 0;
  display: flex;
}

.nav-item {
  margin-right: 15px;
}

.nav-item a {
  text-decoration: none;
  color: white;
  font-weight: bold;
}

.nav-item a:hover {
  color: #ffc107; /* Change color on hover */
}

.logout-button {
  margin-right: 25px;
  background-color: #007bff;
  color: white;
  padding: 12px 20px;
  border: none;
  border-radius: 4px;
  font-size: 16px;
  cursor: pointer;
  transition: background-color 0.3s ease, transform 0.3s ease;
}

.logout-button:hover {
  background-color: #0056b3;
}

.logout-button:active {
  transform: translateY(2px);
}

